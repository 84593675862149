<template>
    <div id="footer">
        <div id="left">
            <div class="signature">
                Zakenkantoor
                <span class="large-font">Verleyen <span class="small-font">BV</span></span>
            </div>
            <p v-if="isVijve">Sint-Elooisplein 41, 8793 Waregem</p>
            <p v-else>Grote Steenweg 75, 9870 Zulte</p>
            <p v-if="isVijve">Tel. 056 60 49 56</p>
            <p v-else>Tel. 09 388 94 85</p>
            <ul class="legal">
                <li>KBO Kortrijk 0640.904.239</li>
                <li>FSMA 114861 cA</li>
                <li><a href="https://www.dvv.be/nl/wettelijke-informatie/wettelijke-informatie.html">Wettelijke
                        informatie</a></li>
                <li><a href="https://www.dvv.be/nl/wettelijke-informatie/beleid-rond-belangenconflicten.html">Beleid rond
                        belangenconflicten</a></li>
                <li><a href="https://www.dvv.be/nl/wettelijke-informatie/duurzaamheid.html">Duurzaam en verantwoord
                        investeren</a></li>
            </ul>
            <p>Ondernemingsrechtbank Gent afdeling Kortrijk</p>
            <p>Beheerstraat 41, 8500 Kortrijk</p>
        </div>

        <div class="links">
            <h3>Links</h3>
            <ul>
                <li><a href="#about">Over Ons</a></li>
                <li><a href="#insurance">Verzekeringen</a></li>
                <li><a href="#credits">Kredieten</a></li>
                <li><a href="#saving">Sparen & Beleggen</a></li>
                <li><a href="#independentssme">Zelstandigen & KMO'S</a></li>
                <li><a href="#contact">Contact</a></li>
                <li><a target="blank" href="https://www.dvv.be/nl/contact/klachten.html">Klachten</a></li>

                <li><a target="blank" href="https://www.dvv.be/nl.html">DVV Verzekeringen</a></li>
                <li><a target="blank"
                        href="https://www.dvv.be/nl/wettelijke-informatie/wettelijke-informatie/privacycharter.html">Privacycharter</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isVijve: Boolean,
        };
    },

    created() {
        this.isVijve = this.$router.history.current.path.includes("vijve")

    }
}
</script>

<style>
#left {
    font-family: regular;

    float: left;
    margin-top: 30px;
    margin-left: 150px;
}

.links {
    margin-left: 230px;
    margin-top: 30px;
    float: left;
    font-family: regular;
}


@media screen and (max-width: 685px) {
    #left {
        float: none;
    }

    .links {
        float: none;
        margin-left: 150px;
    }

}




@media screen and (max-width: 575px) {
    #left {
        margin-left: 5px;
    }

    .links {
        margin-left: 5px;
    }


}
</style>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.small-font {
    margin-right: 50px;
}

#footer {
    overflow: auto;

    text-align: left;
    margin-top: 30px;

    height: auto;
    background-color: #f7f7f7;
    border-top: #b9b9b9 solid 1px;
    border-bottom: #b9b9b9 solid 1px;

}

.social a {
    text-decoration: none;
    color: black;
}



.social a:hover {
    color: #ff9e16;
}

.social {
    margin-top: 10px;
    font-size: 2.4em;
    margin-bottom: 10px;
}

ul.social li {
    display: inline-block;
    padding-right: 20px;
}

.legal {
    margin-top: 30px;
}

.links h3 {
    margin-left: 0px;
    font-family: semibold;
    color: black
}



.links ul li {
    margin-top: 9px;
    margin-bottom: 9px;
}

.signature {
    font-family: semibold;
}
</style>
